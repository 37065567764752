<template>
  <div class="main-body">
    <a-tabs v-model:activeKey="activeKey" @change="changeTab">
      <a-tab-pane key="0" tab="全部"></a-tab-pane>
      <a-tab-pane :tab="item.app_name" v-for="(item, index) in appListData" :key="index"></a-tab-pane>
    </a-tabs>
    <a-row :gutter="16" v-if="listData.length > 0">
      <a-col :span="4" v-for="item in listData" :key="item">
        <a-card hoverable class="advs-item">
          <template #cover>
            <img :preview="true" alt="advs-image" class="advs-image-cover" :src="item.thumb" />
          </template>
          <template #actions>
            <flag-outlined />
            <edit-outlined key="edit" @click="edit(item)" />
            <delete-outlined key="delete" @click="del(item)" />
          </template>
          <a-card-meta :title="item.advs_name">
            <template #description>{{item.start_time}}至{{item.end_time}}</template>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
    <a-empty v-else />
  </div>
</template>
<script>
import { defineComponent, ref, onMounted, reactive, createVNode } from "vue";
import {
  FlagOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons-vue";
// import { message, Modal } from "ant-design-vue";
import * as Api from "@/addons/advs/api";
import { message, Modal } from "ant-design-vue";
import { useRouter } from "vue-router";

export default defineComponent({
  components: {
    FlagOutlined,
    EditOutlined,
    DeleteOutlined,
  },
  setup() {
    const router = useRouter();

    const formState = reactive({
      loading: false,
    });

    const activeKey = ref("0");
    const listData = ref([]);
    // 请求列表数据
    const onLoadData = () => {
      Api.lists({ app_id: activeKey.value }).then((res) => {
        console.log(res);
        listData.value = res.data;
      });
    };

    const changeTab = () => {
      onLoadData();
    };

    //获取客户端列表
    const appListData = ref();
    const getAppList = () => {
      Api.app_list().then((res) => {
        appListData.value = res.data;
      });
    };
    const edit = (item) => {
      router.push({
        path: "/advs/manage/edit",
        query: { adv_id: item.adv_id },
      });
    };

    const del = (item) => {
      Modal.confirm({
        title: "确定要删除【" + item.advs_name + "】吗?",
        icon: createVNode(ExclamationCircleOutlined),
        content: "删除后将无法恢复，请谨慎操作！",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk() {
          Api.del({ adv_id: item.adv_id }).then((res) => {
            message.success(res.message);
            onLoadData();
          });
        },
        onCancel() {
          // console.log("Cancel");
        },
      });
    };
    // 初始化
    onMounted(() => {
      getAppList();
      onLoadData();
    });

    return {
      activeKey,
      formState,
      changeTab,
      appListData,
      listData,
      edit,
      del,
    };
  },
});
</script>

<style scoped lang="less">
.advs-item {
  margin-bottom: 20px;
}
.advs-image-cover {
  object-fit: cover;
  height: 150px;
  width: 250px;
}
</style>